<template>
  <v-dialog v-model="visible" persistent max-width="800">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 px-0 font-weight-regular">
        {{ title }}
      </v-card-text>
      <v-divider class="mb-4" />
      <v-data-table
        id="table-custom-pegawai"
        class="mt-3"
        :headers="attendanceHeader"
        :items="historyAttendances"
      >
        <template v-slot:[`item.attendance_date`]="{ item }">
          {{ item.attendance_date | date }}
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      title: "",
      historyAttendances: [],
      attendanceHeader: [
        {
          text: "Tanggal",
          value: "attendance_date",
          sortable: false,
          align: "center"
        },
        {
          text: "Jam Kerja",
          value: "jam_kerja",
          sortable: false,
          align: "center"
        },
        {
          text: "Masuk",
          value: "check_in_time",
          sortable: false,
          align: "center"
        },
        {
          text: "Keluar",
          value: "check_out_time",
          sortable: false,
          align: "center"
        },
        {
          text: "Keterangan",
          value: "schedule_type",
          sortable: false,
          align: "center"
        }
      ]
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    }
  },
  methods: {
    view(item) {
      this.title = item.title;
      this.historyAttendances = item.historyAttendances;
      this.visible = true;
    }
  }
};
</script>
